.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 16px;
  overscroll-behavior: "none";
}


/* Required for Dashboard Carousel as arrow buttons are white */
.slick-prev:before, .slick-next:before { 
  /* color:#029BDC !important; */
  padding-top: "3px";
  margin-bottom: "-6px";
  padding: "-2px";
}

.slick-dots {
  margin-top: 1px; /* Adjust this value to reduce the space */
  padding-top: 0; /* Ensure there is no padding if needed */
}

.apexcharts-tooltip {
  color: #000 !important;
  font-weight: bold;
}

@keyframes growShrink {
  from {
      transform: scale(1);
  }
  to {
      transform: scale(1.05);
  }
}